import DataTable from "react-data-table-component";
import React from "react";
import styled from "styled-components";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

class SitesTable extends React.Component {
  constructor(props) {
    super(props);
    this.username = "plugininfo";
    this.password = "5l26rmPH78n97EQ1";

    this.token = btoa(this.username + ":" + this.password);
    this.maxVersion = 0;
    this.state = { unfilteredData: [], filter: "", filteredData: [] };

    this.tableColumns = [
      {
        name: "ID",
        selector: (row) => row.id,
        type: "num",
        sortable: true,
      },
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: "Domain",
        selector: (row) => row.domain,
        sortable: true,
      },
      {
        name: "Installed",
        selector: (row) => row.installed,
        type: "num",
        sortable: true,
      },
      {
        name: "Active",
        selector: (row) => row.active,
        type: "num",
        sortable: true,
      },
      {
        name: "Inactive",
        selector: (row) => row.inactive,
        type: "num",
        sortable: true,
      },
      {
        name: "Debug-Log",
        selector: (row) => row.debug_log,
        sortable: true,
      },
      {
        name: "Debug-Log (in Mb)",
        selector: (row) => row.debug_log_size,
        type: "num",
        sortable: true,
      },
    ];
  }

  componentDidUpdate(prevProps) {
    console.log(this.props);
    if (prevProps.data !== this.props.data) {
      const tableData = this.props.data.map((site, index) => {
        return {
          id: parseInt(site.id),
          title: site.title,
          domain: site.domain,
          installed: parseInt(site.plugins.installed),
          active: parseInt(site.plugins.active),
          inactive: parseInt(site.plugins.inactive),
          debug_log:
            null === site.debug_log || "" === site.debug_log
              ? ""
              : "1" === site.debug_log ||
                1 === site.debug_log ||
                true === site.debug_log
              ? "Aktiv"
              : "Inaktiv",
          debug_log_size:
            null === site.debug_log_size || "" === site.debug_log_size
              ? 0
              : Math.round(parseInt(site.debug_log_size) / 10000) / 100,
        };
      });
      this.setState({ tableData: tableData, filteredData: tableData });
    }
  }

  onFilterChange = (e) => {
    const filterInput = e.target.value;
    const filteredData = this.getFilteredData(filterInput);
    this.setState({
      unfilteredData: this.state.tableData,
      filter: filterInput,
      filteredData: filteredData,
    });
  };

  getFilteredData = (filterInput) => {
    const dataUnfiltered = this.state.tableData;
    let filteredData = dataUnfiltered.filter((item) => {
      return (
        item.domain &&
        item.domain.toLowerCase().includes(filterInput.toLowerCase())
      );
    });

    return filteredData;
  };

  render() {
    return (
      <>
        <label for="domain-search">Domain Filter:</label>
        <TextField
          id="domain-search"
          type="text"
          name="domain-search"
          value={this.state.filter}
          onChange={this.onFilterChange}
        />

        <DataTable
          columns={this.tableColumns}
          data={this.state.filteredData}
          conditionalRowStyles={[
            {
              when: (row) => row.debug_log === "Aktiv",
              style: {
                backgroundColor: "#FFE97C",
                color: "black",
              },
            },
          ]}
        />
      </>
    );
  }
}

export default SitesTable;
