import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import Home from "./routes/home";
import Plugins from "./routes/plugins";
import Sites from "./routes/sites";
import Themes from "./routes/themes";
import Updates from "./routes/updates";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="plugins" element={<Plugins />} />
        <Route path="themes" element={<Themes />} />
        <Route path="updates" element={<Updates />} />
        <Route path="sites" element={<Sites />} />
      </Route>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
