import React from "react";
import Select from "react-select";
import PluginTable from "../components/PluginTable";

class PluginSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: "" };
  }

  render() {
    const options = [];
    this.props.data.forEach((plugin) => {
      options.push({ value: plugin.id, label: plugin.name });
    });
    return (
      <div>
        <Select
          onChange={(e) => {
            this.setState({ selected: e });
            console.log(this.state.selected);
          }}
          options={options}
        />
        <PluginTable data={this.state.selected.value} />
      </div>
    );
  }
}

export default PluginSelect;
