import { useMemo, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

const getPropByString = (obj, propString) => {
  if (!propString) return obj;

  var prop,
    props = propString.split(".");

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
};

const { vfs } = pdfFonts.pdfMake;
pdfMake.vfs = vfs;

pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
  FontAwesome: {
    normal: "fa-regular-400.ttf",
    bold: "fa-regular-400.ttf",
    italics: "fa-regular-400.ttf",
    bolditalics: "fa-regular-400.ttf",
  },
};

const getDocDefinition = (item) => {
  console.log(item);
  let pdfBody = [["Name", "Anmerkung", { text: "\f00c", style: "symbol" }]];
  item.plugins.plugins.forEach((plugin) => {
    pdfBody.push([plugin.name, "", ""]);
  });
  const docDefinition = {
    info: {
      title: "Maintenance Checkliste - " + item.domain,
      author: "INCREON Digital Lab GmbH",
      subject: "Checkliste Maintenance",
    },
    content: [
      { text: "Maintenance Checkliste", style: "header" },
      "Aenean commodo ligula eget dolor. Etiam vitae tortor. Suspendisse non nisl sit amet velit hendrerit rutrum. Etiam vitae tortor. Etiam sollicitudin, ipsum eu pulvinar rutrum, tellus ipsum laoreet sapien, quis venenatis ante odio sit amet eros.",
      { text: "Plugins", style: "subheader" },
      {
        style: "tableExample",
        table: {
          widths: [200, "*", 50],
          body: pdfBody,
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      symbol: {
        font: "FontAwesome",
      },
    },
  };
  return docDefinition;
};

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (
          getPropByString(a, sortConfig.key) <
          getPropByString(b, sortConfig.key)
        ) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (
          getPropByString(a, sortConfig.key) >
          getPropByString(b, sortConfig.key)
        ) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const UpdateTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.data);
  const getDirectionFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>#</th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("title")}
              className={getDirectionFor("title")}
            >
              Name{" "}
              {getDirectionFor("title") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("title") ? "ASC" : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("domain")}
              className={getDirectionFor("domain")}
            >
              Domain{" "}
              {getDirectionFor("domain") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("domain") ? "ASC" : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>Checkliste</th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("updates.plugins")}
              className={getDirectionFor("updates.plugins")}
            >
              Plugins{" "}
              {getDirectionFor("updates.plugins") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("updates.plugins")
                    ? "ASC"
                    : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("updates.themes")}
              className={getDirectionFor("updates.themes")}
            >
              Themes{" "}
              {getDirectionFor("updates.themes") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("updates.themes")
                    ? "ASC"
                    : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("updates.translations")}
              className={getDirectionFor("updates.translations")}
            >
              Übersetzungen{" "}
              {getDirectionFor("updates.translations") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("updates.translations")
                    ? "ASC"
                    : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("updates.wordpress")}
              className={getDirectionFor("updates.wordpress")}
            >
              WordPress{" "}
              {getDirectionFor("updates.wordpress") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("updates.wordpress")
                    ? "ASC"
                    : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("updates.total")}
              className={getDirectionFor("updates.total")}
            >
              Gesamt{" "}
              {getDirectionFor("updates.total") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("updates.total")
                    ? "ASC"
                    : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("last_update")}
              className={getDirectionFor("last_update")}
            >
              Letztes Update{" "}
              {getDirectionFor("last_update") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("last_update")
                    ? "ASC"
                    : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          const date = new Date(item.last_update);
          const today = new Date();
          return (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.title}</td>
              <td>{item.domain}</td>
              <td>
                <Button
                  variant="secondary"
                  onClick={() => {
                    const dd = getDocDefinition(item);
                    //pdfMake.createPdf(dd).open();
                    pdfMake
                      .createPdf(dd)
                      .download(
                        "maintenance-checklist_" +
                          item.domain +
                          "_" +
                          today.getDate() +
                          "-" +
                          (today.getMonth() + 1) +
                          "-" +
                          today.getFullYear() +
                          ".pdf"
                      );
                  }}
                >
                  Checkliste
                </Button>
              </td>
              <td>{item.updates.plugins}</td>
              <td>{item.updates.themes}</td>
              <td>{item.updates.translations}</td>
              <td>
                {item.updates.wordpress === "1"
                  ? "Update verfügbar"
                  : "Aktuell"}
              </td>
              <td>{item.updates.total}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                {date.toLocaleDateString("de-DE", options)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default UpdateTable;
