import axios from "axios";
import DataTable from "react-data-table-component";
import React from "react";

class PluginTable extends React.Component {
  constructor(props) {
    super(props);
    this.username = "plugininfo";
    this.password = "5l26rmPH78n97EQ1";

    this.token = btoa(this.username + ":" + this.password);
    this.maxVersion = 0;
    this.state = { tableData: [] };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.data = axios
        .get(
          "https://wpplugininfo.increon.digital/rest/get-domains-by-plugin.php",
          {
            headers: {
              Authorization: `Basic ${this.token}`,
            },
            params: {
              pluginID: this.props.data,
            },
          }
        )
        .then((response) => {
          if (response.data[0]) {
            const tableData = response.data.map((domain, index) => {
              return {
                id: index,
                name: domain.name,
                url: domain.url,
                version: domain.version,
                active: domain.active,
              };
            });
            tableData.sort(versionSort);
            this.maxVersion = tableData[0].version;
            this.tableColumns = [
              {
                name: "Name",
                selector: (row) => row.name,
                sortable: true,
              },
              {
                name: "URL",
                selector: (row) => row.url,
                sortable: true,
              },
              {
                name: "Version",
                selector: (row) => row.version,
                sortable: true,
                sortFunction: versionSort,
              },
              {
                name: "Active",
                selector: (row) => row.active,
                sortable: true,
              },
            ];
            this.setState({ tableData: tableData });
          }
        });
    }
  }

  render() {
    return (
      <DataTable
        columns={this.tableColumns}
        data={this.state.tableData}
        conditionalRowStyles={[
          {
            when: (row) => row.version !== this.maxVersion,
            style: {
              backgroundColor: "#FFE97C",
              color: "black",
            },
          },
          {
            when: (row) => row.active !== 1,
            style: {
              backgroundColor: "#E44334",
              color: "black",
            },
          },
        ]}
      />
    );
  }
}

export default PluginTable;

/**
 * Compare 2 Version tags whilst accounting for different lengths.
 */
function versionCompare(v1, v2, options) {
  var lexicographical = options && options.lexicographical,
    zeroExtend = options && options.zeroExtend,
    v1parts = v1.split("."),
    v2parts = v2.split(".");

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push("0");
    while (v2parts.length < v1parts.length) v2parts.push("0");
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

/**
 * custom sort for version column
 */
const versionSort = (a, b) => {
  return versionCompare(b.version, a.version);
};
