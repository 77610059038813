import { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row } from "react-bootstrap";
import SitesTable from "../components/SitesTable";

const Plugins = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const username = "plugininfo";
    const password = "5l26rmPH78n97EQ1";
    const token = btoa(username + ":" + password);

    const getData = () => {
      axios
        .get("https://wpplugininfo.increon.digital/rest/get.php", {
          headers: {
            Authorization: `Basic ${token}`,
          },
        })
        .then(function (response) {
          setData(response.data);
        });
    };
    getData();
  }, []);
  return (
    <Container id="main" className="my-5" fluid>
      <Container className="mb-3">
        <Row>
          <h1 className="display-3">Sites</h1>
        </Row>
      </Container>
      <Container className="mb-3">
        <Row className="bg-light">
          <SitesTable data={data} />
        </Row>
      </Container>
    </Container>
  );
};
export default Plugins;
