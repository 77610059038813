import { Container, Row } from "react-bootstrap";

const Home = () => {
  return (
    <Container id="main" className="my-5" fluid>
      <Container className="mb-3">
        <Row>
          <h1 className="display-3">Home</h1>
          <p>
            Sed aliquam ultrices mauris. Proin pretium, leo ac pellentesque
            mollis, felis nunc ultrices eros, sed gravida augue augue mollis
            justo. Etiam vitae tortor. Nulla sit amet est. Sed mollis, eros et
            ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor
            urna a orci. Praesent porttitor, nulla vitae posuere iaculis, arcu
            nisl dignissim dolor, a pretium mi sem ut ipsum. Ut tincidunt
            tincidunt erat. Aenean vulputate eleifend tellus. Suspendisse non
            nisl sit amet velit hendrerit rutrum. Proin magna. Praesent ut
            ligula non mi varius sagittis. Pellentesque auctor neque nec urna.
            Vestibulum ullamcorper mauris at ligula. Proin magna. Etiam feugiat
            lorem non metus.
          </p>
        </Row>
      </Container>
    </Container>
  );
};
export default Home;
