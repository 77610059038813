import { Container, Nav, Navbar } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";

import "./App.css";

const App = () => {
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Plugin Charts</Navbar.Brand>
          <Nav className="me-auto">
            <Link to="/plugins" className="nav-link">
              Plugins
            </Link>
            <Link to="/themes" className="nav-link">
              Themes
            </Link>
            <Link to="/updates" className="nav-link">
              Updates
            </Link>
            <Link to="/sites" className="nav-link">
              Sites
            </Link>
          </Nav>
        </Container>
      </Navbar>
      <Outlet />
      <Navbar bg="dark" variant="dark">
        <Container>
          <Nav className="me-auto">
            <Nav.Link href="https://increon-digitallab.com/" target="_blank">
              INCREON Digital Lab GmbH
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default App;
