import { useMemo, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";

const getPropByString = (obj, propString) => {
  if (!propString) return obj;

  var prop,
    props = propString.split(".");

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
};

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (
          getPropByString(a, sortConfig.key) <
          getPropByString(b, sortConfig.key)
        ) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (
          getPropByString(a, sortConfig.key) >
          getPropByString(b, sortConfig.key)
        ) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const ThemeTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.data);
  const getDirectionFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>#</th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("title")}
              className={getDirectionFor("title")}
            >
              Name{" "}
              {getDirectionFor("title") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("title") ? "ASC" : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("domain")}
              className={getDirectionFor("domain")}
            >
              Domain{" "}
              {getDirectionFor("domain") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("domain") ? "ASC" : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("themes.installed")}
              className={getDirectionFor("themes.installed")}
            >
              Installiert{" "}
              {getDirectionFor("themes.installed") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("themes.installed")
                    ? "ASC"
                    : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button
              variant="secondary"
              onClick={() => requestSort("themes.activated.name")}
              className={getDirectionFor("themes.activated.name")}
            >
              Aktiv (Version){" "}
              {getDirectionFor("themes.activated.name") ? (
                <Badge bg="light" text="dark">
                  {"ascending" === getDirectionFor("themes.activated.name")
                    ? "ASC"
                    : "DESC"}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </th>
          <th style={{ whiteSpace: "nowrap" }}>
            <Button variant="secondary">Avada-Version</Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const avada = item.themes.themes.find((theme) => {
            return theme.name === "Avada";
          });
          return (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.title}</td>
              <td>{item.domain}</td>
              <td>{item.themes.installed}</td>
              <td>
                {item.themes.activated.name} ({item.themes.activated.version})
              </td>
              <td>{typeof avada !== "undefined" ? avada.version : "-"}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ThemeTable;
